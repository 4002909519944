input, textarea, select {
    background: $white-color;
    border: 1px solid $border-color;
    box-shadow: none;
    display: block;
    padding: .7rem .75rem;
    width: 100%;
    font-family: $font-roboto;
    font-size: $base-font-size;
    font-weight: 500;
    line-height: 15px;
    color: $text-color;
    z-index: 1;
    @include placeholder;

    @include border-radius(3px);

    &.form-control:focus {
        outline: 0;
        box-shadow: none;
    }
}

textarea {
    resize: none;
}

select:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
    padding: 0 15px;
    height: 35px;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    width: 100%;

    > .custom-file,
    > .custom-select,
    > .form-control,
    > input {
        position: relative;
        -webkit-box-flex: 1;
        width: 1%;
        margin-bottom: 0px;
        flex: 1 1 auto;
        padding: 9px 15px;
    }
    

    > :first-child {
        border-radius: 3px 0px 0px 3px;
    }

    > :last-child {
        border-radius: 0px 3px 3px 0px;
    }
}