/*
    Author: Design Team Fusemachines Nepal
    Author URI: https://fusemachines.com.np/
    Description: Fusemachines
    Template: Sanish karmacharya
    Version: 1.0
*/

// bootstrap
@import "bootstrap/grid";

/* // utilities */
// @import "fonts/custom-font";
@import "utilities/variables";
@import "utilities/mixins";
@import "utilities/utilities";

// /* // Layout */
@import "layout/global";
@import "layout/sidenav";
@import "layout/form";
@import "layout/pagination";
@import "layout/table";

// /* // pages */
@import "pages/app";
@import "pages/responsive";
