html,body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-family: $font-roboto;
    font-size: $base-font-size;
    color: $text-color;
    scroll-behavior: smooth;
    line-height: 18px;
    font-weight: $font-normal;
}

*:focus {
    outline: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li{
        padding: 7px 0;
    }
}

ol {
    ul {
        list-style: circle;
        margin-left: 15px;

        ul {
            list-style: disc;
            margin-left: 15px;
        }
    }
}

a {
    color: $primary-color;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: $primary-dark-color;
        text-decoration: none;
    }
}

select {
    color: $text-color;
}

.hide {
    display: none;
}

hr {
    border: 0;
    border-bottom: 1px solid $faded-color;

    &.divider {
        margin: 10px 0;
    }
}

.link {
    @extend a;
}