@mixin vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin box-shadow($left, $blur, $size, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $left $blur $size $color;
        -moz-box-shadow: inset $left $blur $size $color;
        box-shadow: inset $left $blur $size $color;
    }
    @else {
        -webkit-box-shadow: $left $blur $size $color;
        -moz-box-shadow: $left $blur $size $color;
        box-shadow: $left $blur $size $color;
    }
}

// Add new mizins.scss
@mixin translate ($left, $top) {
    -webkit-transform: translate($left, $top);
    -moz-transform: translate($left, $top);
    -ms-transform: translate($left, $top);
    -o-transform: translate($left, $top);
    transform: translate($left, $top);
}

@mixin transition ($transition-property, $transition-duration, $timing-function) {
    -webkit-transition: $transition-property $transition-duration $timing-function;
    -moz-transition: $transition-property $transition-duration $timing-function;
    -o-transition: $transition-property $transition-duration $timing-function;
    transition: $transition-property $transition-duration $timing-function;
}

@mixin grayscale ($gray) {
    -webkit-filter: grayscale($gray);
    -moz-filter: grayscale($gray);
    filter: grayscale($gray);
}

@mixin rotate ($deg) {
    transform: rotate($deg);
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -ms-transform: rotate($deg);
}

@mixin transform-scale($scaleX, $scaleY) {
    transform: scale($scaleX, $scaleY);
    -webkit-transform: scale($scaleX, $scaleY);
    -moz-transform: scale($scaleX, $scaleY);
    -ms-transform: scale($scaleX, $scaleY);
}

@mixin font-size($fontSize, $lineHeight) {
    font-size: $fontSize;
    line-height: $lineHeight;
}

// fiter type and amount
@mixin filter($filter-type, $filter-amount) {
    -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
    -moz-filter: $filter-type+unquote('(#{$filter-amount})');
    -ms-filter: $filter-type+unquote('(#{$filter-amount})');
    -o-filter: $filter-type+unquote('(#{$filter-amount})');
    filter: $filter-type+unquote('(#{$filter-amount})');
}

@mixin linear-gradient($direction, $color-stops...) {
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}

@mixin border-radius( $radius ) {
    border-radius: $radius;
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
    // Firefox
    &::-moz-placeholder {
      color: $color;
      opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    }
    &:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
    &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

@mixin whitespace($width) {
    max-width: $width;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
}