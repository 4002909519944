// table
.table-responsive {
    margin-top: 5px;
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    height: calc(100vh - 107px);
    position: relative;

    .podcasts & {
        height: calc(100vh - 155px);
    }
}

.table {
    background: $white-color;
    border-radius: 3px;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0rem;
    border-collapse: collapse;
    color: $text-light-color;
    font-weight: $font-normal;

    thead {
        background: darken($grey-color, 3%);
        font-weight: $font-semi-bold;
        .checkbox-ui span {
            border-color: $white-color;
        }
        
        th {
            border-width: 1px;
            padding: 15px 12px;
            text-align: left;
            vertical-align: middle;
            min-width: 150px;

            &.xs {
                min-width: 50px;
            }

            &.sm {
                min-width: 80px;
            }

            &.md {
                min-width: 180px;
            }

            &.lg {
                min-width: 280px;
            }
        }
        
    }
    
    .datetime {
        max-width: 100px;
    }

    .f-lt {
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        background: lighten($primary-color, 20%);
        color: $white-color;
        border-radius: 50%;
        line-height: 30px;
        overflow: hidden;
        margin-right: 15px;
        vertical-align: middle;

        img {
            max-width: 100%;
        }
    }

    tbody tr {
        td {
            vertical-align: middle;
            padding: 15px 12px;
            border-top: 1px solid $border-color;
            font-size: $small-font-size;
            line-height: 16px;
        }

        &:hover {
            box-shadow: rgb(244, 246, 250) 0px 0px 10px;
        }
    }

    thead th:first-child th,
    tbody tr:first-child td,
    tbody tr:first-child th {
        border-top: 0;
    }
}