#root {
  display: flex;
  height: 100%;

  .main-container {
    width: calc(100% - 200px);
    background: $grey-color;
    padding: 0 15px;
    height: 100%;
    overflow: auto;

    .top-section {
      background: $white-color;
      padding: 10px 0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  .logo {
    display: block;
    padding: 10px 15px;
    
    img {
      max-width: 100%;
      height: 28px;
    }
  }
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  
  svg,
  img {
      @include vertical-align;
  }
}

.see-more {
  color: #2074b9;
  cursor: pointer;

  &:hover {
    color: #115D9B;
  }
}
.tippy-content {
  max-height: 400px;
  overflow: auto;
}

.btn {
  border: 0;
  border-radius: 4px;
  padding: 8px 15px;
  cursor: pointer;
}

.btn-danger {
  background: red;
  color: #fff;
  &:hover {
    background: darken(red, 20%);
  }
}

.ReactModal__Overlay {
  z-index: 2;
  top: 0;
  bottom: 0;
  .ReactModal__Content {
    position: relative !important;
    max-width: 100%;
    width: 500px;
    position: relative;
    margin: auto;
    top: 20%;

    .head {
      border-bottom: 1px solid;
      h4 {
        margin: 0 0 12px 0;
      }
    }
    .m-body{
      padding: 12px 0;
    }
    .m-footer {
      display: flex;
      padding: 12px 0 0;
      justify-content: end;
    }
  }
}

.podcasts-tab {
  display: flex;
  margin-top: 10px;

  .item {
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px 4px 0 0;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #115d9b26;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
      background: white;
    }
  }
}