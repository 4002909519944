.container-pagination{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;

    .pagination {
        display: flex;
        gap: 5px;
        align-items: center;

        li {
            a {
                padding: 4px 8px;
                color: $black;
                border-radius: 2px;
                display: block;
                line-height: 1.5;

                &:hover {
                    background-color: $primary-light-color;
                    color: white;

                    svg path{
                        stroke :white;
                    }
                }
            }

            &.next,&.previous {
                a {
                    padding: 7px 8px;
                    svg {
                        display: block;
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            &.active a {
                color: white;
                background-color: $primary-color;
            }

            &.disabled a {
                cursor: default;
            }
        }
    }
}