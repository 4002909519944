// Text Color
.text-c {
    color: $text-color;
}

.teal-c {
    color: $teal-color;
}

.faded-c {
    color: $text-light-color;
}

.faded-text {
    color: $faded-color;
}

.white-c {
    color: $white-color;
}

.red-c {
    color: $red-color;
}

// margin
.mr{

    &-top {
        margin-top: 50px;
    }

    &-l {
        margin-top: 25px;
    }

    &-tl {
        margin-top: 15px;
    }

    &-btn {
        margin-bottom: 25px;
    }

    &-n {
        margin: 0;
    }

    &-t-b {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

// padding
.p{
    &-t-b {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    &-all {
        padding: 25px 25px;;
    }

    &-al {
        padding: 15px 15px;
    }
}

// border
.bor {
    
    &-top {
        border-top: 1px solid $faded-color;
    }
    
    &-btn {
        border-bottom: 1px solid $faded-color;
    }

    &-right {
        border-right: 1px solid $faded-color;
    }
}

// background color
.blue-bg {
    background: $primary-color;
}

.grey-bg {
    background: $grey-color;
}

.black-bg {
    background: $text-color;
}

.yellow-bg {
    background: $yellow-color;
}

.red-bg {
    background: $red-color;
}

.white-bg {
    background: $white-color;
}

.green-bg{
    background: $green-color;
}

// Font Size & line height
.base-text-size {
    font-size: $base-font-size;
    line-height: 20px;
}

.small-text-size {
    @include font-size($small-font-size, calcEm(22px));
}

.large-text-size {
    font-size: $large-font-size;
    line-height: 28px;
}

.xlarge-text-size {
    @extend h5;
}

.h2 {
    font-size: $h2-font-size;
    line-height: 50px;
    color: $text-color;

    @extend .bold-s;
}

.h3 {
    font-size: $h3-font-size;
    line-height: 36px;
}

.h4 {
    font-size: $h4-font-size;
    line-height: 32px;
}

.h5 {
    font-size: $h5-font-size;
    line-height: 26px;
}

h1 {
    font-weight: $font-extra-bold;
    font-size: $h1-font-size;
    line-height: 77px;
    color: $text-color;
}

h2 {
    @extend .h2;
    color: $text-color;
}

h3 {
    @extend .h3;
    color: $text-color;
}

h4 {
    @extend .h4;
    color: $text-color;
}

h5 {
    @extend .h5;
    color: $text-color;
}

// text placement
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-up {
    text-transform: uppercase;
}

.clear-both {
    &:before,
    &:after {
        display: table;
        content: '';
    }
    &:after {
        clear: both;
    }
}

.v-align {
    top: 50%;
    @include translate(0, -50%);
}

.c-align {
    left: 50%;
    @include translate(-50%, 0);
}

.v-c-align {
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
}

.bold {
    font-weight: $font-bold;
}

.bold-s {
    font-weight: $font-semi-bold;
}

.bold-n {
    font-weight: $font-normal;
}

.normal {
    font-weight: 300;
}

// images
.img{

    &-responsive {
        max-width: 100%;
    }

    &-hold-sm {
        width: 60px;
    }

    &-logo {
        width: 30px;
    }
}

// border radius

.bor-r{
    &-4 {
        border-radius: 4px;
    }
}

// border-color transition
.border-trans {
    border: 0;
    position: relative;
    transition: color 0.2s;
    overflow: hidden;

    &:before,
    &:after {
        box-sizing: inherit;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
        border: 2px solid transparent;
        width: 0;
        height: 0;
        content: "";
        border-radius: 10px;
    }

    // This covers the top & right borders (expands right, then down)
    &:before {
        top: 0;
        left: 0;
    }

    // And this the bottom & left borders (expands left, then up)
    &:after {
        bottom: 0;
        right: 0;
    }

    &:hover {
        color: inherit;
    }

    // Hover styles
    &:hover:before,
    &:hover:after {
        width: 100%;
        height: 100%;
    }

    &:hover:before {
        border-top-color: $red-color; // Make borders visible
        border-right-color: $red-color;
        
        transition:
                width 0.1s ease-out, // Width expands first
                height 0.1s ease-out 0.1s; // And then height
    }

    &:hover:after {
        border-bottom-color: $red-color; // Make borders visible
        border-left-color: $red-color;

        transition:
                border-color 0s ease-out 0.1s, // Wait for ::before to finish before showing border
                width 0.1s ease-out 0.1s, // And then exanding width
                height 0.1s ease-out 0.2s; // And finally height

    }
}

.hide {
    visibility: hidden;
}

.show {
    visibility: visible;
}

.scrollbar {
    --scrollbarBG: #cfd8dc;
    --thumbBG: $green;
  
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  
    overflow: auto !important;
  
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        color-stop(0.5, rgba(255, 255, 255, 0.2)),
        color-stop(0.5, transparent),
        to(transparent)
      );
    }
}