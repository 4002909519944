// side nav
aside {
    background: $primary-light-color;
    position: relative;
    width: 200px;

    .nav {
        width: 100%;
        height: calc(100% - 93px);
        overflow: auto;

        @extend .scrollbar;

        li {
            padding: 0;
            display: block;
            border-top: 1px solid $primary-color;
            
            .link {
                display: block;
                color: $white-color;
                text-decoration: none;
                padding: 10px 15px;
                line-height: 1.5;

                &:hover,
                &.active {
                    background: $primary-dark-color;
                }
            }
        }
    }

    .logout {
        display: block;

        button {
            display: block;
            padding: 12px 15px;
            width: 100%;
            border: 0;
            color: #fff;
            background: darken($primary-color, 5%);
            text-align: left;
            cursor: pointer;

            &:hover,
            &.active {
                background: $primary-color;
            }
        }
    }
}