// color
$primary-color: #2074B9;
$primary-dark-color: #074477;
$primary-light-color: #115D9B;

$teal-color: #1E9C9F;
$teal-dark-color: #1C8184;

$red-color: #E84C3D;
$yellow-color: #F2C40F;
$green-color: #2ECD71;

$text-color: #012645;
$text-light-color: #737373;

$faded-color: #F2F2F2;

$grey-color: #F9F9F9;

$white-color: #ffffff;

$border-color: #EBEBEB;


//** Placeholder text color
$input-color-placeholder: $border-color !default;

// font size
$base-font-size: 14px;
$h1-font-size: 54px;
$h2-font-size: 40px;
$h3-font-size: 28px;
$h4-font-size: 24px;
$h5-font-size: 18px;


$small-font-size: 12px;
$large-font-size: 16px;

// font weight
$font-roboto: 'Open Sans', sans-serif;
$font-light: 400;
$font-normal: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 900;

// Fonts Size & Function
@function calcEm($size) {
    $emSize: $size / $base-font-size;
    @return $emSize * 1em;
} 

